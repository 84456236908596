<template>
  <div>
    <!-- Controllers -->
    <div
      color="white"
      class="d-flex justify-end py-4 pr-6"
    >
      <v-btn color="primary" type="submit" class="mr-3" dark depressed @click.prevent="update()" v-text="$t('common.save')" />
    </div>
    <!-- Body -->
    <v-container class="px-6 pt-0 pb-6 bigScreenForm">
      <v-row>
        <v-col md="8" sm="12">
          <!-- Name and Location -->
          <v-row style="height: auto;">
            <v-col>
              <v-row>
                <v-col
                  id="name"
                  cols="11"
                >
                  <h3 class="field-title" v-text="$t('project.editProject.generalInformation')" />
                  <v-text-field
                    v-model="form.name"
                    :error-messages="getFieldErrors('name')"
                    :label="$t('common.name')"
                    outlined
                    dense
                    hide-details="auto"
                    class="my-2"
                    @blur="$v.form.name.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  id="language"
                  :cols="isAcademy ? 5 : 11"
                >
                  <v-select
                    v-model="form.language"
                    :error-messages="getFieldErrors('language')"
                    :label="$t('project.editProject.language')"
                    :items="locales"
                    item-value="code"
                    item-text="language"
                    outlined
                    dense
                    hide-details="auto"
                    class="my-2"
                    @blur="$v.form.language.$touch()"
                  />
                </v-col>
                <v-col cols="1" class="pa-0 d-flex flex-column justify-center align-start">
                  <global-tooltip
                    :text="$t('project.editProject.languageTooltip')"
                  />
                </v-col>
                <v-col v-if="isAcademy" cols="5">
                  <v-text-field
                    v-model="form.timezone"
                    :label="$t('common.timeZone')"
                    outlined
                    dense
                    hide-details="auto"
                    class="my-2"
                    disabled
                    :error-messages="getFieldErrors('timezone')"
                    @blur="$v.form.date.$touch()"
                  />
                </v-col>
                <v-col v-if="isAcademy" cols="1" />
              </v-row>
              <v-row v-if="isCompetition">
                <v-col cols="11">
                  <v-select
                    v-model="form.calendarType"
                    :label="$t('project.editProject.calendarType')" :items="calendarTypes" outlined dense hide-details="auto"
                    class="my-2" @blur="$v.form.calendarType.$touch()"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  id="countries"
                  cols="5"
                >
                  <v-select
                    v-model="form.countries"
                    :error-messages="getFieldErrors('countries')"
                    :label="`${$t('common.countries')} ${$t('common.optional')}`"
                    :items="countryItems"
                    item-text="text"
                    item-value="value"
                    multiple
                    outlined
                    dense
                    hide-details="auto"
                    class="my-2"
                    @blur="$v.form.countries.$touch()"
                  >
                    <template #selection="{ item, index }">
                      <span v-if="index === 0">{{ item.text }}</span>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption ml-1"
                      >
                        {{ `+${form.countries.filter(country => country !== 'all').length - 1} ${$t('common.countries')}` }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="1" />
                <v-col
                  v-if="isAcademy"
                  id="city"
                  cols="5"
                >
                  <v-text-field
                    v-model="form.city"
                    :label="$t('common.city')"
                    outlined
                    dense
                    hide-details="auto"
                    class="my-2"
                    :error-messages="getFieldErrors('city')"
                    @blur="$v.form.city.$touch()"
                  />
                </v-col>
                <v-col v-else cols="5">
                  <v-text-field
                    v-model="form.timezone"
                    :label="$t('common.timeZone')"
                    outlined
                    dense
                    hide-details="auto"
                    class="my-2"
                    disabled
                    :error-messages="getFieldErrors('timezone')"
                    @blur="$v.form.date.$touch()"
                  />
                </v-col>
                <v-col cols="1" />
              </v-row>
            </v-col>
          </v-row>
          <!-- Age and Dates -->
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="5">
                  <v-text-field
                    :label="`${$t('project.editProject.minAge')} ${$t('common.optional')}`"
                    type="number" min="0" step="1"
                    outlined
                    dense
                    class="my-2"
                    hide-details="auto"
                    :value="form.age.min"
                    :error-messages="getFieldErrors('age.min')"
                    @input="form.age.min = $event === '' ? null : Number($event)"
                    @blur="$v.form.age.min.$touch()"
                  />
                </v-col>
                <v-col cols="1" />
                <v-col cols="5">
                  <v-text-field
                    :label="`${$t('project.editProject.maxAge')} ${$t('common.optional')}`"
                    type="number" min="0" step="1"
                    outlined
                    dense
                    class="my-2"
                    hide-details="auto"
                    :value="form.age.max"
                    :error-messages="getFieldErrors('age.max')"
                    @input="form.age.max = $event === '' ? null : Number($event)"
                    @blur="$v.form.age.max.$touch()"
                  />
                </v-col>
                <v-col cols="1" />
              </v-row>
              <v-row class="mt-4">
                <v-col
                  id="dateRange"
                  cols="5"
                >
                  <date-range-field
                    v-model="form.dateRange"
                    :is-single-date="isLongTerm"
                    :label="$t(isLongTerm ? 'project.editProject.dateStart': 'project.editProject.dateRange')"
                    :timezone="form.timezone"
                    :error-messages="getFieldErrors('dateRange')"
                    @input="newValue => form.dateRange = newValue"
                    @blur="$v.form.dateRange.$touch()"
                    @resetDate="form.dateRange = null"
                  />
                </v-col>
                <v-col cols="1" class="pl-0 mt-2">
                  <global-tooltip
                    :text="dateRangeText()"
                  />
                </v-col>
                <v-col
                  id="deadline"
                  cols="5"
                >
                  <date-range-field
                    v-model="form.deadline"
                    :is-single-date="true"
                    :label="`${$t('common.deadline')} ${$t('common.optional')}`"
                    :timezone="form.timezone"
                    :error-messages="getFieldErrors('deadline')"
                    @input="newValue => form.deadline = newValue"
                    @blur="$v.form.deadline.$touch()"
                    @resetDate="form.deadline = null"
                  />
                </v-col>
                <v-col cols="1" class="pl-0 mt-2">
                  <global-tooltip
                    :text="$t('project.editProject.enrolmentDeadlineTooltip')"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Only Minor -->
          <v-row v-if="project.features.terms">
            <v-col class="ma-0 mb-4 pt-0">
              <v-checkbox
                v-model="form.onlyMinors" :label="$t('project.editProject.onlyMinors')" hide-details="auto"
                class="ma-0 pa-0" @change="$v.form.onlyMinors.$touch()"
              />
            </v-col>
          </v-row>
          <!-- Tags -->
          <v-row>
            <v-col v-if="isLongTerm" cols="11">
              <v-combobox
                :value="form.tags"
                :label="$t('common.tags')"
                multiple
                deletable-chips
                append-icon=""
                :delimiters="[',']"
                small-chips
                outlined
                @input="form.tags = Array.from(new Set($event.map(tag => tag.trim())))"
              />
            </v-col>
          </v-row>
          <!-- Text Description -->
          <v-row no-gutters>
            <v-col
              id="cardDescription"
              cols="11"
              class="mt-4"
            >
              <v-textarea
                v-model="form.cardDescription"
                :error="form.cardDescription.length > 73"
                :error-messages="getFieldErrors('cardDescription')"
                :label="$t('project.createProjectDialog.cardDescription')"
                counter
                maxlength="73"
                outlined
                dense
                class="mt-1"
                height="200"
                @blur="$v.form.cardDescription.$touch()"
              />
            </v-col>
            <v-col
              id="detailDescription"
              cols="11"
              class="mt-4"
            >
              <html-field
                v-model="form.detailDescription"
                :placeholder="$t('project.editProject.detailDescription')"
                :error-messages="getFieldErrors('detailDescription')"
                @blur="$v.form.detailDescription.$touch()"
              />
            </v-col>
          </v-row>
          <!-- Logo and Banner -->
          <v-row class="mt-8">
            <v-col cols="11">
              <v-row>
                <v-col
                  id="logo"
                  class="field"
                  cols="12"
                  sm="6"
                >
                  <div class="mb-4 d-flex">
                    <h3 class="mr-3" v-text="$t('common.logo')" />
                    <global-tooltip
                      :text="$t('common.tooltipImageSizes', {
                        proportion: '1:1',
                        width: '200px',
                        height: '200px'
                      })"
                    />
                  </div>
                  <image-field
                    v-model="form.logo"
                    :error-messages="getFieldErrors('logo')"
                    :width="200" :height="200"
                    suffix="_logo" @input="$v.form.logo.$touch()"
                  />
                </v-col>
                <v-col
                  id="banner"
                  class="field"
                  cols="12"
                  sm="6"
                >
                  <div class="mb-4 d-flex">
                    <h3 class="mr-3" v-text="$t('project.editProject.banner')" />
                    <global-tooltip
                      :text="$t('common.tooltipImageSizes', {
                        proportion: '16:9',
                        width: '640px',
                        height: '360px'
                      })"
                    />
                  </div>
                  <image-field
                    v-model="form.banner"
                    :error-messages="getFieldErrors('banner')"
                    :width="640" :height="360"
                    suffix="_banner" @input="$v.form.banner.$touch()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Video -->
          <v-row class="mt-8">
            <v-col class="field" cols="11">
              <div class="mb-4 d-flex">
                <h3 class="mr-3" v-text="`${$t('project.editProject.video')} ${$t('common.optional')}`" />
                <global-tooltip
                  :text="$t('project.editProject.tooltipVideoYoutube')"
                />
              </div>
              <video-field
                v-model="form.video"
                :is-short-term="isShortTerm"
                :error-messages="getFieldErrors('video')" @blur="$v.form.video.$touch()"
              />
            </v-col>
          </v-row>
          <!-- Count subscribers -->
          <v-row class="mt-8">
            <v-col class="field" cols="12">
              <h3 class="field-title" v-text="$t('project.editProject.numberSubscribersTitle')" />
              <v-row class="d-flex align-center mx-0 my-3">
                <v-switch
                  v-model="form.seeNumberSubscribers"
                  class="mt-0 mr-3 py-1"
                  hide-details="auto"
                  :label="$t('project.editProject.features.seeNumberSubscribers')" @change="$v.form.seeNumberSubscribers.$touch()"
                />
                <global-tooltip
                  :text="$t('project.editProject.features.seeNumberSubscribersTooltip')"
                />
              </v-row>
              <v-row v-if="!isCompetition && !isAcademy" class="d-flex align-center mx-0 mb-3">
                <v-switch
                  v-model="form.seeViewersStreaming"
                  class="mt-0 mr-3 py-1"
                  hide-details="auto"
                  :label="$t('project.editProject.features.seeViewersStreaming')" @change="$v.form.seeViewersStreaming.$touch()"
                />
                <global-tooltip
                  :text="$t('project.editProject.features.seeViewersStreamingTooltip')"
                />
              </v-row>
            </v-col>
          </v-row>
          <!-- Notifications -->
          <v-row class="mt-8 mb-1">
            <v-col class="field" cols="11">
              <h3 class="field-title" v-text="$t('project.editProject.automaticNotifications')" />
              <v-switch
                v-if="!project.published"
                v-model="form.doSendNotificationOnPublish"
                hide-details="auto"
                class="mt-0 mr-3 py-1"
                :label="$t('project.editProject.features.doSendNotificationOnPublish')" @change="$v.form.doSendNotificationOnPublish.$touch()"
              />
              <v-switch
                v-model="form.doSendNotificationOnActivityPublish"
                class="mt-0 mr-3 py-1"
                hide-details="auto"
                :label="$t('project.editProject.features.doSendNotificationOnActivityPublish')" @change="$v.form.doSendNotificationOnActivityPublish.$touch()"
              />
            </v-col>
          </v-row>
          <!-- Translation -->
          <v-row v-if="!isCompetition && !isAcademy" class="mt-8">
            <v-col class="field" cols="12">
              <h3 class="field-title" v-text="$t('project.editProject.automaticTranslations')" />
              <v-row class="d-flex align-center mx-0 my-3">
                <v-switch
                  v-model="form.needsTranslation"
                  class="mt-0 mr-3 py-1"
                  hide-details="auto"
                  :disabled="!isUserSuperadmin"
                  :label="$t('project.editProject.features.needsTranslation')" @change="$v.form.needsTranslation.$touch()"
                />
                <global-tooltip
                  :text="$t('project.editProject.features.needsTranslationTooltip')"
                />
              </v-row>
            </v-col>
          </v-row>
          <!-- Ads -->
          <v-row v-if="!isCompetition" class="mt-8">
            <v-col cols="11" class="pb-0">
              <h3 class="field-title" v-text="$t('project.editProject.sponsorization')" />
              <v-checkbox v-model="form.hasAds" :label="$t('project.editProject.isSponsorized')" hide-details="auto" />
              <v-text-field
                v-if="form.hasAds"
                v-model="form.ads.link"
                class="mt-6"
                :error-messages="getFieldErrors('ads.link')"
                :label="$t('project.editProject.sponsorLink')"
                outlined
                dense
                @blur="$v.form.ads.link.$touch()"
              />
            </v-col>
            <v-row class="field">
              <v-col cols="11">
                <v-row>
                  <v-col v-if="form.hasAds" cols="11" sm="6">
                    <div class="mb-4 d-flex">
                      <h3 class="mr-3" v-text="$t('project.editProject.sponsorFull')" />
                      <global-tooltip
                        :text="$t('common.tooltipImageSizes', {
                          proportion: '16:9',
                          width: '640px',
                          height: '360px'
                        })"
                      />
                    </div>
                    <image-field v-model="form.ads.full" :width="640" :height="360" can-be-deleted suffix="_adFull" @input="$v.form.ads.full.$touch()" />
                  </v-col>
                  <v-col v-if="form.hasAds" cols="12" sm="6">
                    <div class="mb-4 d-flex">
                      <h3 class="mr-3" v-text="$t('project.editProject.sponsorFooter')" />
                      <global-tooltip
                        :text="$t('common.tooltipImageSizes', {
                          proportion: '8:1',
                          width: '640px',
                          height: '80px'
                        })"
                      />
                    </div>
                    <image-field
                      v-model="form.ads.footer"
                      :error-messages="getFieldErrors('ads.footer')"
                      :width="640" :height="80"
                      suffix="_adFooter" @input="$v.form.ads.footer.$touch()"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-row>
          <!-- Categories -->
          <v-row
            v-if="requiresCategories"
            class="mt-8"
          >
            <v-col
              cols="11"
            >
              <div
                :style="{
                  background: $vuetify.theme.themes.light.inactive,
                  border: '2px solid #dadada',
                  borderRadius: '12px',
                  boxShadow: `2px 2px 2px 1px ${$vuetify.theme.themes.light.inactive}`,
                }"
              >
                <competition-crud-list
                  :key="categories.length"
                  :title="$t('competition.edit.categories')"
                  :tooltip-text="$t('competition.edit.tooltip')"
                  :add-button-text="$t(`competition.structure.categories`)"
                  :eddit-button-text="$t(`competition.edit.insertName`)"
                  :items="categories"
                  @onChanged="(items) => categories = [...items]"
                />
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isCompetition" class="mt-8">
            <v-col cols="12">
              <h3 class="mb-4" v-text="$t('common.privacyPolicy')" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.privacyPolicyUrl"
                :label="`${$t('project.editProject.privacyPolicyUrl')} ${$t('common.optional')}`"
                :error-messages="getFieldErrors('privacyPolicyUrl')" outlined dense class="my-2"
                @blur="$v.form.privacyPolicyUrl.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isCompetition">
            <v-col cols="12">
              <h3 class="mb-4" v-text="$t('project.editProject.linkCompetitionForm')" />
            </v-col>
            <v-col cols="12">
              <v-text-field :label="$t('common.link')" outlined dense readonly disabled :value="competitionFormLink">
                <template #append>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="verifyPrivacyUrl(); copyToClipboard(competitionFormLink)">
                        content_copy
                      </v-icon>
                    </template>
                    {{ $t('club.editClub.copyLink') }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, maxLength, requiredIf, url, integer, between } from 'vuelidate/lib/validators'
import i18nCountries from '@/utils/mixins/i18nCountries'
import i18n from '@/plugins/i18n'
import formUtils from '@/utils/mixins/formUtils'
import localeUtils from '@/utils/mixins/localeUtils'
import saveDialogUtils from '@/utils/mixins/saveDialog'
import getBasePath from '@/utils/getBasePath'

export default {
  name: 'EditProject',
  components: {
    HtmlField: () => import('@/components/formFields/HtmlField'),
    ImageField: () => import('@/components/formFields/ImageField'),
    VideoField: () => import('@/components/formFields/VideoField'),
    DateRangeField: () => import('@/components/formFields/DateRangeField'),
    CompetitionCrudList: () => import('@/modules/competition/components/CompetitionCrudList'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
  },
  mixins: [localeUtils, formUtils, saveDialogUtils, i18nCountries],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      form: {},
      categories: [],
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ allTerms: 'project/terms' }),
    ...mapGetters({ isShortTerm: 'project/isShortTerm' }),
    ...mapGetters({ isLongTerm: 'project/isLongTerm' }),
    ...mapGetters({ isCompetition: 'project/isCompetition' }),
    ...mapGetters({ isAcademy: 'project/isAcademy' }),
    ...mapGetters({ hasCategoryFeatures: 'project/hasCategoryFeatures' }),
    ...mapGetters({ competitionCategories: 'competition/categories' }),
    ...mapGetters({ customCategories: 'project/categories' }),
    ...mapGetters({ isUserSuperadmin: 'superadmin/isUserSuperadmin' }),
    ...mapGetters('project', ['isCompetition']),
    ...mapGetters('clubEnrollmentForm', ['template']),
    requiresCategories({ isCompetition, hasCategoryFeatures, isAcademy }) { // TODO: Remove this computed and the <!-- Categories --> <v-row> section after the categories refactor
      // return (isCompetition || hasCategoryFeatures) && !isAcademy
      return false
    },
    terms({ project, allTerms }) {
      return allTerms?.filter(terms => terms.language === project?.terms?.defaultLanguage)[0] ?? ''
    },
    minAgeMinYear: () => new Date().getFullYear() - 100,
    minAgeMaxYear: () => new Date().getFullYear() - 4,
    maxAgeMinYear: () => new Date().getFullYear() - 100,
    maxAgeMaxYear: ({ form }) => form?.age?.min || new Date().getFullYear() - 4,
    competitionFormLink: ({ organizationId, projectId }) => `${getBasePath()}/organization/${organizationId}/project/${projectId}/club-enrollment-form`,
    calendarTypes: () => [{ text: i18n.t('common.day'), value: 'day' }, { text: i18n.t('common.month'), value: 'month' }],
  },
  validations() {
    return {
      form: {
        type: { required },
        name: { required },
        language: { required },
        ...(this.isCompetition && { calendarType: {} }),
        timezone: { required },
        countries: {},
        city: { required: requiredIf(() => this.isAcademy) },
        age: {
          min: { integer, between: between(this.minAgeMinYear, this.minAgeMaxYear) },
          max: { integer, between: between(this.maxAgeMinYear, this.maxAgeMaxYear) },
        },
        dateRange: { required },
        deadline: {},
        cardDescription: { required, maxLength: maxLength(73) },
        detailDescription: { required },
        logo: { required },
        banner: { required },
        video: {},
        ads: {
          full: {},
          footer: { required: requiredIf(() => this.form.hasAds) },
          link: { url },
        },
        ...(this.isCompetition && { privacyPolicyUrl: { url } }),
        doSendNotificationOnPublish: {},
        ...(this.project.features.terms && { onlyMinors: {} }),
        doSendNotificationOnActivityPublish: {},
        needsTranslation: {},
        seeNumberSubscribers: {},
        seeViewersStreaming: {},
      },
    }
  },
  created() {
    this.form = {
      name: this.project.name,
      language: this.project.language,
      ...(this.isCompetition && { calendarType: this.project?.features?.calendarType ?? 'day' }),
      type: this.project.type,
      timezone: this.project.timezone,
      countries: this.project?.countries?.length ? this.project.countries : [],
      city: this.project?.city ?? null,
      age: this.project?.age ?? { min: null, max: null },
      dateRange: this.isLongTerm ? this.project?.date?.start : this.project?.date,
      deadline: this.project?.deadline,
      cardDescription: this.project?.cardDescription ?? '',
      detailDescription: this.project.detailDescription,
      ...(this.isLongTerm && { tags: this.project.tags ?? [] }),
      logo: this.project.logo,
      banner: this.project.banner,
      video: this.project.video ?? null,
      hasAds: this.project.ads?.footer ?? this.project.ads?.full,
      ads: this.project.ads ?? { full: null, footer: null, link: null },
      ...(this.isCompetition && { privacyPolicyUrl: this.template?.privacyPolicyUrl ?? null }),
      doSendNotificationOnPublish: !this.project?.features?.doNotSendNotificationOnPublish,
      onlyMinors: this.project?.features?.onlyMinors ?? false,
      doSendNotificationOnActivityPublish: !this.project?.features?.doNotSendNotificationOnActivityPublish,
      needsTranslation: this.project?.features?.needsTranslation ?? false,
      seeNumberSubscribers: !this.project?.features?.removeSubscribersLabel ?? false,
      seeViewersStreaming: this.project?.features?.seeViewersStreaming ?? false,
    }

    if (this.isCompetition) this.categories = this.competitionCategories.sort(this.orderByName)
    if (this.hasCategoryFeatures) this.categories = this.customCategories.sort(this.orderByName)
  },
  methods: {
    orderByName: (a, b) => a?.name?.localeCompare(b?.name),
    parseCountries(formCountries) {
      return formCountries.filter(country => country !== 'all')
    },
    verifyPrivacyUrl() {
      if (!this.form.privacyPolicyUrl) {
        this.$confirm(this.$t('project.editProject.haveConfigurePrivacyPolicy'), { buttonTrueText: 'Ok', buttonFalseText: '' })
      }
    },
    parseForm() {
      return (({
        name, hasAds, ads, dateRange, deadline, countries: formCountries, city,
        needsTranslation, calendarType, seeViewersStreaming, privacyPolicyUrl, ...rest
      }) => ({
        ...rest,
        name: name.trim(),
        ...(!this.isLongTerm && ({ date: dateRange?.start || dateRange?.end ? dateRange : null })),
        ...(this.isLongTerm && ({ date: dateRange ? { start: dateRange } : null })),
        ...({ deadline: deadline?.seconds || deadline?.nanoseconds ? deadline : null }),
        ...({ countries: this.parseCountries(formCountries).length ? this.parseCountries(formCountries) : ['all'] }),
        ...(this.isAcademy && ({ city })),
        ads: hasAds ? ads : null,
        features: {
          ...this.project?.features,
          ...(this.isCompetition && { calendarType: this.form.calendarType }),
          doNotSendNotificationOnPublish: !this.form.doSendNotificationOnPublish,
          onlyMinors: this.form.onlyMinors ?? false,
          doNotSendNotificationOnActivityPublish: !this.form.doSendNotificationOnActivityPublish,
          needsTranslation,
          seeViewersStreaming,
          removeSubscribersLabel: !this.form.seeNumberSubscribers,
        },
      }))(this.form)
    },
    async update() {
      const { organizationId, project, parseForm, categories } = this
      if (!this.isFormValid()) {
        this.scrollToFirstError()
        return
      }
      await this.runAsync(() => this.$store.dispatch('project/update', { organizationId, project, data: parseForm(), categories }))
      if (this.isCompetition) {
        const { form: { privacyPolicyUrl } } = this
        await this.runAsync(() => this.$store.dispatch('clubEnrollmentForm/createOrUpdateClubEnrollmentForm', { organizationId, projectId: this.project.id, data: { privacyPolicyUrl } }))
      }
      this.userAgreedToLeave = true
    },
    dateRangeText() {
      let text
      switch (true) {
      case this.isShortTerm:
        text = this.$t('project.editProject.enrolmentDateTooltipShort')
        break
      case this.isLongTerm:
        text = this.$t('project.editProject.enrolmentDateTooltipLong')
        break
      default:
        text = this.$t('project.editProject.enrolmentDateTooltipOther')
        break
      }
      return text
    },
  },
}
</script>

<style lang="scss" scoped>

.field-title {
  margin-bottom: 16px;
}

.field {
  padding: 4px 12px;
}
</style>
